import * as React from "react"
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Helmet>
      <script src="https://donorbox.org/widget.js" paypalExpress="false"></script>
    </Helmet>
    <SEO title="Home" />
    <div className="section">
    <div className="image-desktop-right">
    <StaticImage
      src="../images/flag.png"
      width={400}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="An American Flag"
      style={{ marginBottom: `1.45rem` }}
    />
    </div>
    <h1>
      Our Mission
      <span className="text-red">.</span>
    </h1>
    <p>Honor 4 Life is committed to supporting and rebuilding the lives of military veterans and first responders who have courageously served our country and communities.</p>
    <p>We strive to empower these brave men and women in the Houston Metropolitan Area and its surrounding cities, during their transition to civilian life.  We encourage and help them to build or modify their homes and communities in an eco-friendly and specially adapted approach.  We will help focus to lead a resilient and productive life.</p>
    </div>

    <div className="section-light-start"></div>
    <div className="section-light has-text-centered">
    <div className="content">
    <StaticImage
      src="../images/board-photo.jpg"
      width={1024}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="the board of directors"
      style={{  marginBottom: '1rem', boxShadow: '0px 20px 20px rgba(0,0,0,0.1)' }}
    />
    <h1>
      Who We Are
      <span className="text-red">.</span>
    </h1>
    <h4>Board of Directors</h4>
    <div className="director-list">
      <div className="director">
        <h2>Chris Barge</h2>
        <h4>Executive Director</h4>
      </div>
      <div className="director">
        <h2>Richard J. Voorhies</h2>
        <h4>Director</h4>
      </div>
      <div className="director">
        <h2>Joseph E. Stearns, Jr.</h2>
        <h4>Secretary</h4>
      </div>
    </div>
    </div>
    </div>
    <div className="section-light-end"></div>

    <div className="section">
    <h1>
      Our Values
      <span className="text-red">.</span>
    </h1>
    <p>In order to provide a positive work environment for everyone, regardless of status or position, each employee is responsible for internalizing and illustrating our core values.</p>
    <div className="image-desktop-right">
    <StaticImage
      src="../images/bricks.png"
      width={400}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="An American Flag"
      style={{ marginBottom: `1.45rem` }}
    />
    </div>
    <h4>Purpose</h4>
    <p>This Organization has the power to change lives through advocacy and respect.</p>
    <h4>Accountability</h4>
    <p>We empower individuals we serve, improving the Organization, and enhancing the Organizations effectiveness.  It means being responsible for the results that others – customers both internal and external – expect of you.  Ultimately, when the team consistently demonstrates ownership and accountability, trust is formed.</p>
    <h4>Selfless Service</h4>
    <p>We are committed in helping those in need.  Our customers are at the center of everything we do.  We are driven by demands given by our customers and the pursuit for customer excellence.</p>
    <h4>Stewardship</h4>
    <p>We are socially, financially and environmentally responsible.  Stewardship reflects our vision and direction of where this Organization is going and all team members understanding this direction.  We are also acting as stewards for our customers in making sure they continue to move forward in their healing.</p>
    <h4>Integrity</h4>
    <p>We adhere to moral and ethical principles in our Organization.  When faced with difficult decisions and hard choices, we do the right thing, even in the face of adversity.  Carrying out each action with honesty will foster trustworthiness amongst each other, with our customers, and in dealing with other stakeholders.  Doing things with integrity is not optional or situational; because integrity is the foundation of good leadership, demonstrating this core value is integral to gaining trust of our team members, managers, customers, and the communities we work in.</p>
    <h4>Openness</h4>
    <p>We provide full disclosure on issues around conflict of interest, operations, and funding practices.  Whether things are good or bad, this Organization will be open and honest.  When a new team member joins our team, they can feel confident that working for this Organization was the best thing they did.  When a donor sends money to the Organization, they can be confident their money will be used properly to support our customers.</p>
    <h4>Nurturing</h4>
    <p>Building relationships with our customers by using empathy and listening to them so we can illustrate that we are genuinely invested in helping them.</p>
    <h4>Appreciation</h4>
    <p>We create a workplace where team members feel they are listened to, recognized and appreciated, and understand they are a valuable asset to this Organization’s success.</p>
    <h4>Teamwork</h4>
    <p>We trust each other, no matter what the circumstances.  Our best work is produced through collaboration as a team by supporting each other daily.  This also extends to our customers and stakeholders where we approach every relationship or partnership working collaboratively to meet our goals.</p>
    <h4>Efficiency</h4>
    <p>Obtaining the best possible outcome in the least wasteful manner using innovative approaches and by maintaining dynamic, relevant policies and procedures while consistently supporting a quality work environment.</p>
    </div>

    <div className="section-very-light-start"></div>
    <div className="section-very-light">
    <div className="content">
    <h1>
      Make a Donation
      <span className="text-red">.</span>
    </h1>
    <iframe allowpaymentrequest="" frameborder="0" height="900px" name="donorbox" scrolling="no" seamless="seamless" src="https://donorbox.org/embed/honor-4-life" style={{maxWidth: '500px', minWidth: '250px', maxHeight: 'none !important'}} width="100%"></iframe>
    </div>
    </div>
    <div className="section-very-light-end"></div>

  </Layout>
)

export default IndexPage
